<script setup lang="ts">
  import { object, string } from 'yup'
  import SpaceFormErrorModal from '~/components/modals/SpaceFormErrorModal.vue'
  import GenericSuccessModal from '~/components/modals/GenericSuccessModal.vue'
  import NewsletterService from '~/services/NewsletterService'
  import { useModalStore } from '~/stores/modalStore'
  import GenericErrorModal from '~/components/modals/GenericErrorModal.vue'

  const email = ref('')
  const isFormInvalid = ref(false)
  const formErrorMessages = ref([])

  const emailValidationSchema = object({
    email: string()
      .required('É obrigatório informar o email')
      .email('E-mail inválido'),
  })

  const subscriptionSuccess =
    'Cadastro efetuado com sucesso. Obrigado por se cadastrar em nossa newsletter.'

  function handleInvalidForm(form: any) {
    isFormInvalid.value = true
    formErrorMessages.value = form.errors
  }

  const submitForm = async ({}, { resetForm }: any) => {
    try {
      await NewsletterService.postNewsletterSubscription({ email: email.value })
      useModalStore().successMessage = subscriptionSuccess
      useModalStore().showSuccessModal = true
      resetForm()
    } catch (error: any) {
      if (error.message) {
        useModalStore().showErrorModal = true
        useModalStore().errorMessage = error.message ?? ''
      }
    }
  }
</script>

<template>
  <div class="mt-10 bg-white py-16 sm:pb-10 sm:pt-12">
    <SpaceFormErrorModal
      :open="isFormInvalid"
      :messages="formErrorMessages"
      @close-modal="isFormInvalid = false" />
    <GenericSuccessModal />
    <GenericErrorModal />
    <!--FetchLoading /-->
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div
        class="relative isolate flex flex-col gap-10 overflow-hidden bg-white px-6 py-24 shadow-xl ring-1 ring-gray-100 sm:rounded-3xl sm:px-24 md:flex-row xl:flex-row xl:items-center xl:py-32">
        <h2
          class="max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl xl:max-w-none xl:flex-auto">
          Receba novidades e promoções diretamente em seu e-mail.
        </h2>
        <Form
          :validation-schema="emailValidationSchema"
          :on-invalid-submit="handleInvalidForm"
          class="w-full max-w-md"
          @submit="submitForm">
          <div class="flex flex-col gap-x-4 md:flex-row">
            <label
              for="email"
              class="sr-only"
              >endereço de email</label
            >
            <Field
              id="email"
              v-model="email"
              as="input"
              name="email"
              type="email"
              autocomplete="email"
              class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
              placeholder="Informe seu e-mail" />
            <button
              type="submit"
              class="mt-2.5 flex-none rounded-md bg-orange-400 px-3.5 py-2 text-base font-semibold text-white shadow-sm ring-1 ring-orange-400 hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white md:mt-0">
              Me avise!
            </button>
          </div>
          <p class="mt-4 text-sm leading-6 text-gray-400">
            Não enviamos span. Nossos e-mails são apenas de novas ofertas,
            promoções e novidades do site e blog.
          </p>
          <SiteBgGuides />
        </Form>
      </div>
    </div>
  </div>
</template>
