import { RestService } from '~/services/RestService'
import Endpoints from '~/models/constants/Endpoints'
import type { NewsletterSubscriptionForm } from '~/models/forms/NewsletterSubscriptionForm'

class NewsletterService extends RestService {
  async postNewsletterSubscription(
    newsletter: Partial<NewsletterSubscriptionForm>
  ) {
    const response = await this.postSecured(
      `${Endpoints.NEWSLETTER}/subscriptions`,
      {
        ...newsletter,
      },
      {}
    )

    return this.handleResponse(response)
  }
}
export default new NewsletterService()
